<div class="form-check">
  <input
    type="checkbox"
    [disabled]="isDisabled"
    id="{{ 'spot_check' + id }}"
    class="form-check-input"
    [class.is-invalid]="hasError"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
  />
  <label class="form-check-label ms-2" for="{{ 'spot_check' + id }}">{{
    label
  }}</label>
</div>
